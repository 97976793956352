var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "floating-buttons"
  }, [_c('router-link', {
    staticClass: "fl-button grey",
    attrs: {
      "to": {
        path: '/cardgroups/'
      }
    }
  }, [_c('i', {
    staticClass: "customicon customicon-angle-left"
  })]), _c('button', {
    staticClass: "fl-button",
    attrs: {
      "disabled": _vm.is_save,
      "type": "button"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('i', {
    staticClass: "customicon customicon-save"
  })])], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kártyacsoport")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xs-6"
  }, [_c('inputbox', {
    attrs: {
      "id": "name",
      "label": "Megnevezés",
      "errors": _vm.errors,
      "name": "name"
    },
    model: {
      value: _vm.form.group.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.group, "name", $$v);
      },
      expression: "form.group.name"
    }
  }), _c('select2ajax', {
    attrs: {
      "method": "post",
      "multi": "true",
      "url": _vm.searchCardsUrl,
      "label": "Kártyák",
      "id": "cards",
      "name": "cards"
    },
    model: {
      value: _vm.form.cards,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cards", $$v);
      },
      expression: "form.cards"
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }